import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import enJson from "./locale/en.json";
import esJson from "./locale/es.json";
import ptJson from "./locale/pt.json";

const resources = {
    en: {
        translation: { ...enJson }
    },
    es: {
        translation: { ...esJson }
    },
    pt: {
        translation: { ...ptJson }
    }
};

const DETECTION_OPTIONS = {
    order: ['localStorage','navigator'],
    caches: ['localStorage']
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        detection: DETECTION_OPTIONS,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;